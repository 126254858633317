<template>
  <div class="px-2">
    <treeview-root
      v-model="checked"
      selected-color="teal lighten-1"
      :items="items"
      :openItems="['DH', 'DS', 'DNG']"
      @input="handleCheck"
      item-key="groupPath"
      item-text="localization"
    />
    <div class="text-caption ml-1 py-2 pt-0 font-italic">
      {{ $t('generic.updatesExcluded') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import TreeviewRoot from '@/components/Treeview/Treeview.vue';
import { convertGroupToFilterStructure } from '@/helpers/groupingHelper';
import { showDialog } from '@/helpers/dialogHelper';
import NlFiltersExplanationDialog from './NlFiltersExplanationDialog.vue';

export default {
  components: {
    TreeviewRoot,
  },

  data: () => ({
    checkedFilterChoices: [],
    filterType: 'groupPaths',
  }),

  computed: {
    ...mapGetters({
      allSearchParameters: 'allSearchParameters',
      filters: 'userFilters',
    }),

    items() {
      const { nlGrouping } = this.filters;

      // Update grouping details for searchParameters
      const { name, version } = nlGrouping;
      this.setGroupingDetails({ name, version });

      const items = Object.keys(nlGrouping.groups).map((groupCode) => convertGroupToFilterStructure(
        groupCode, nlGrouping.groups[groupCode],
      ));

      // Add *s to some labels
      if (items) {
        // Anything containing stakeholder or media messages
        const noTrendItems = items.filter((item) => ['SB', 'MD', 'PP'].includes(item.groupPath));
        const dng = items.find((item) => item.groupPath === 'DNG');
        if (dng) {
          const subGroups = dng.children.filter((item) => item.groupPath.startsWith('DNG/SB'));
          noTrendItems.push(...Object.values(subGroups));
        }
        noTrendItems.forEach((item) => { item.localization += '*'; });

        // Add explanation button
        const explanationItems = items.filter((item) => ['SB', 'PP'].includes(item.groupPath));
        explanationItems.forEach((item) => {
          item.explanationFunction = this.openNlFiltersExplanationDialog;
        });
      }

      return items;
    },

    checked: {
      get() {
        const allSearchParameters = this.allSearchParameters[this.filterType];
        return allSearchParameters;
      },
      set(checkedFilterChoices) {
        this.checkedFilterChoices = checkedFilterChoices;
      },
    },
  },

  methods: {
    ...mapState(['searchParameters']),
    ...mapActions([
      'nlGetAllTimelineItems',
      'setCheckboxFilter',
      'setGroupingDetails',
    ]),

    handleCheck() {
      this.setCheckboxFilter({
        filterKey: this.filterType,
        filterValue: this.checkedFilterChoices,
      });
      this.setCheckBoxes();
    },

    setCheckBoxes() {
      const filter = {};
      filter[this.filterType] = this.checkedFilterChoices.join(',');

      // Set the selected filters as querystring of the dashboard
      this.$router.push({
        name: 'nlDashboard',
        query: { ...this.$route.query, ...filter },
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log('route', err);
      });
      this.nlGetAllTimelineItems();
    },

    openNlFiltersExplanationDialog() {
      showDialog(NlFiltersExplanationDialog);
    },
  },

  mounted() {
    this.checkedFilterChoices = [];
    this.setCheckboxFilter({
      filterKey: this.filterType,
      filterValue: this.checkedFilterChoices,
    });
  },
};
</script>
