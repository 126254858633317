<!--
  Component to show the confidentially status by text and icon
-->

<template>
  <span
    v-if="confidentiality != 'default'"
    class="location__access"
    :class="cssClassConfidentiality"
  >
    {{ $t(`confidentiality.${confidentiality}`) }}
  </span>
</template>

<script>
import Config from '@/config';

export default {
  name: 'ConfidentialityStatus',

  props: {
    // Type of confidentiality
    confidentiality: {
      type: String,
      required: true,
      validator(value) {
        return Config.confidentialityTypes.indexOf(value.toLowerCase()) !== -1;
      },
    },
  },

  computed: {
    cssClassConfidentiality() {
      let cssClass;
      switch (this.confidentiality) {
        case 'Openbaar':
          cssClass = 'location__access--public';
          break;
        case 'Besloten':
          cssClass = 'location__access--private';
          break;
        default:
        // do nothing
      }

      return cssClass;
    },
  },
};
</script>

<style scoped lang="scss">
// Default colors, feel free to override in _colors.scss
$color-location--default: #32bc9e !default;
$color-location--public: #32bc9e !default;
$color-location--private: #ff6200 !default;

.location__access {
  color: $color-location--default;
  // font-weight: bold;

  &--public {
    color: $color-location--public;
  }
  &--private {
    color: $color-location--private;
  }
}

.location__access-icon--private {
  margin-bottom: -2px;
}
</style>
